.card{
    display: flex;
    width: 600px;
    height: 450px;
    position: absolute;
    background: white;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
}


.card__exit{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background: red;
}
.card__canvas{
    width: 250px;
    height: 400px;
    background: #F1F1F1;
}
.card__info{
    padding-left: 40px;
}
.card__type{
    font-size: 14px;
    margin: 0;
}
.card__name{
    font-size: 32px;
    font-weight: 300;
    margin: -5px 0 15px 0;
}
.card__sizes{
    display: flex;
}
.size{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    background: #F1F1F1;
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
    border-radius: 4px;
    cursor: pointer;
}
.card__count{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.card__qty{
    margin-right: 20px
}
.card__span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background: #f2f2f2;
    border-radius: 4px;
    padding: 8px 5px 8px 5px;
    border: 1px solid #ddd;
}
.card__input{
    height: 22px;
    width: 45px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
}
.card__colors{
    display: flex;
    margin-bottom: 20px;
}
.card__color{
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin-right: 10px;
}
.active{
    scale: 1.4;
}
.card__color-1{
    background: red;
}
.card__color-2{
    background: green;
}
.card__color-3{
    background: blue;
}
.card__color-4{
    background: yellow;
}
.card__color-5{
    background: black;
}
.card__price{
    font-size: 26px;
}

@media (max-width: 600px) {
    .card{
        width: 100%;
        height: auto;
        flex-direction: column;
    }
    .card__canvas{
        width: 150px;
        height: 150px;
    }

}