.assortment-getter{
    display: none;
    width: 20px;
    height: 20px;
    background: blue;
    position: absolute;
    top: 10px;
    right: 10px;
}
@media (max-width: 900px) {
    .assortment-getter{
        display: block;
    }
}