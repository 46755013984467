.person{
    position: absolute;
    width: 100vw;
    height: 100vh;
}
.person div{
    /*position: absolute;*/
    /*width: 30vw;*/
    /*top: 25vh;*/
    /*left: 0;*/
}

/*@media (max-width: 600px) {*/
/*    .person canvas{*/
/*        width: 100%;*/
/*        height: 100%;*/
/*    }*/
/*}*/